class MShoppingDots {
	/** @type {HTMLElement} element The root element of the component */
	element;

	/** @type {HTMLUListElement} element */
	ulElement;

	/** @param {HTMLElement} element */
	constructor(element) {
		this.element = element;
		this.ulElement = element.querySelector(':scope > ul');

		const liElements = this.ulElement.querySelectorAll(':scope > li');

		element.addEventListener('click', (/** @type {MouseEvent} */ event) => {
			if (event.target.nodeName !== 'A' && event.target.closest('a') === null) {
				this.toggleDots();
			}
		});

		if (window.matchMedia('(pointer: coarse)').matches) {
			// Other behavior for touch devices
			element.querySelectorAll('.m-shopping-dots__dot').forEach((dotElement) => {
				dotElement.setAttribute('role', 'button');
				dotElement.addEventListener('click', (event) => {
					event.preventDefault();
					const popoverElement = event.target.parentElement.querySelector('.m-shopping-dots__popover');
					if (popoverElement) {
						const hidePopover = popoverElement.getAttribute('aria-hidden') === 'false';
						this.closeOtherPopoverElements(popoverElement);
						popoverElement.setAttribute('aria-hidden', hidePopover ? 'true' : 'false');
					}
				});
			});
		} else {
			liElements.forEach((liElement) => {
				liElement.addEventListener('mouseenter', () => {
					const popoverElement = liElement.querySelector('.m-shopping-dots__popover');
					if (popoverElement) {
						this.closeOtherPopoverElements(popoverElement);
						popoverElement.setAttribute('aria-hidden', 'false');
					}
				});
				liElement.addEventListener('mouseleave', () => {
					const popoverElement = liElement.querySelector('.m-shopping-dots__popover');
					if (popoverElement) {
						this.closeOtherPopoverElements();
					}
				});
			});
		}
	}

	closeOtherPopoverElements(expectElement = null) {
		this.element.querySelectorAll('.m-shopping-dots__popover').forEach((popoverElement) => {
			if (popoverElement !== expectElement) {
				popoverElement.setAttribute('aria-hidden', 'true');
			}
		});
	}

	toggleDots() {
		this.ulElement.toggleAttribute('hidden', !this.ulElement.hasAttribute('hidden'));
	}
}

document.querySelectorAll('.m-shopping-dots').forEach((_) => new MShoppingDots(_));
