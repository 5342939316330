const translations = {
    "de": {
        "remove": "entfernen",
        "size": "Variante",
        "cart.clear": "Warenkorb leeren",
        "cart.empty.notice": "Fügen Sie neue Artikel über den Katalog des jeweiligen Systems hinzu.",
        "cart.headline": "Warenkorb",
        "cart.to-checkout": "Zur Kasse",
        "cart.sum": "Summe",
        "cart.net": "Netto",
        "cart.subtotal": "Zwischensumme",
        "cart.tax": "Steuern",
        "cart.tax.info": "Steuern werden nach Auswahl des Landes berechnet.",
        "cart.shipping": "Versandkosten",
        "cart.shipping.info": "Versandkosten werden anhand des Landes und der PLZ berechnet.",
        "cart.discount": "Rabatt",
        "field.discount_code": "Rabattcode",
        "configuration.share-title": "Konfiguration",
        "configuration.save-dialog.title": "Konfiguration gespeichert",
        "configuration.save-dialog.text": "Die Konfiguration wurde gespeichert. Die Konfiguration ist jetzt unter folgendem Link erreichbar.",
        "configuration-request.configuration-data": "Konfigurationsdaten",
        "configuration-request.partlist": "Stückliste",
        "continue": "Weiter",
        "copy-direct-link": "Direktlink kopieren",
        "copy-link": "Link kopieren",
        "delivery-time.delivery_2w": "Lieferzeit zirka 2 Wochen ab Werk Weil am Rhein",
        "delivery-time.delivery_6w": "Lieferzeit zirka 6 Wochen ab Werk Weil am Rhein",
        "field.postal_code": "PLZ",
        "product-type.button.close": "schließen",
        "product-type.view-image": "Bild anzeigen",
        "product.add-to-cart": "In den Warenkorb",
        "product.add-to-wishlist": "merken",
        "product.amount": "Anzahl",
        "product.articleNumber": "Artikelnummer",
        "product.color": "Farbe",
        "product.length": "Länge",
        "product.option": "Option",
        "product.package-contents": "Lieferumfang",
        "product.perMeter": "pro Meter",
        "product.priceUponRequest": "Preis auf Anfrage",
        "product.share": "Produkt teilen",
        "product.system": "System",
        "product.configuration": "Konfiguration",
        "saved-to-clipboard": "In Zwischenablage gespeichert",
        "share": "Teilen",
        "wishlist": "Merkliste",
        "wishlist.activation-status.synced.button": "Merkliste deaktivieren",
        "wishlist.activation-status.synced.text": "Die Merkliste kann über das Herz-Symbol in der Navigationsleiste geöffnet werden. Neue Artikel können über den Katalog des jeweiligen Systems hinzugefügt werden.",
        "wishlist.activation-status.synced.title": "Merkliste ist aktiv.",
        "wishlist.activation-status.unsynced.button": "Merkliste aktivieren",
        "wishlist.activation-status.unsynced.text": "Um zu dieser gespeicherten Merkliste neue Artikel hinzuzufügen, muss die Merkliste aktiviert werden.",
        "wishlist.activation-status.unsynced.title": "Merkliste ist inaktiv.",
        "wishlist.add-to-cart": "In den Warenkorb",
        "wishlist.add-all-to-cart": "Zum Warenkorb hinzufügen",
        "wishlist.clear": "Liste leeren",
        "wishlist.delete": "Löschen",
        "wishlist.download": "Als CSV herunterladen",
        "wishlist.edit": "Bearbeiten",
        "wishlist.empty.notice": "Mit der Merkliste können Sie Ihr individuelles System zusammenstellen. Anschließend können Sie die Merkliste mit unseren Experten teilen.",
        "wishlist.excl-tax": "exkl. MwSt.",
        "wishlist.headline": "Merkliste",
        "wishlist.price-from": "ab",
        "wishlist.price-notice": "Preise verstehen sich ab Werk Weil am Rhein, Deutschland. Preisänderungen vorbehalten.",
        "wishlist.print": "Drucken/PDF",
        "wishlist.remove-product": "Artikel entfernen",
        "wishlist.request": "Angebot anfragen",
        "wishlist.request.headline": "Anfrage für ein Angebot",
        "wishlist.request.submit": "Unverbindlich anfragen",
        "wishlist.save": "Liste speichern",
        "wishlist.saved.headline": "Merkliste gespeichert",
        "wishlist.saved.share-title": "Meine Visplay-Merkliste",
        "wishlist.saved.text": "Die Merkliste ist nun unter folgendem Link gespeichert. Dieser Link kann geteilt werden. Die Merkliste kann von allen bearbeitet werden, die diesen Link kennen.",
        "wishlist.share": "Liste teilen",
        "wishlist.synced.notice": "Verbunden mit gespeicherter Merkliste. Änderungen werden automatisch in die gespeicherte Merkliste übernommen.",
        "wishlist.unlink": "Verbindung aufheben"
    },
    "en": {
        "remove": "remove",
        "size": "Variant",
        "cart.clear": "Clear shopping cart",
        "cart.empty.notice": "New articles can be added directly from the respective system catalogue.",
        "cart.headline": "Shopping cart",
        "cart.to-checkout": "Checkout",
        "cart.sum": "Total",
        "cart.net": "Net",
        "cart.subtotal": "Subtotal",
        "cart.tax": "Taxes",
        "cart.tax.info": "Taxes are calculated according to the country selected.",
        "cart.shipping": "Shipping costs",
        "cart.shipping.info": "Shipping costs are calculated according to the country and postcode. ",
        "cart.discount": "Discount",
        "field.discount_code": "Discount code",
        "configuration.share-title": "Configuration",
        "configuration.save-dialog.title": "Configuration saved",
        "configuration.save-dialog.text": "The configuration has been saved. It can now be accessed using the following link.",
        "configuration-request.configuration-data": "Configuration data",
        "configuration-request.partlist": "Parts list",
        "continue": "Continue",
        "copy-direct-link": "Copy direct link",
        "copy-link": "Copy link",
        "delivery-time.delivery_2w": "Delivery time approx. 2 weeks, ex works Weil am Rhein, Germany",
        "delivery-time.delivery_6w": "Delivery time approx. 6 weeks, ex works Weil am Rhein, Germany",
        "field.postal_code": "Postcode",
        "product-type.button.close": "close",
        "product-type.view-image": "View image",
        "product.add-to-cart": "Add to shopping cart",
        "product.add-to-wishlist": "Add to favourites",
        "product.amount": "Quantity",
        "product.articleNumber": "Part number",
        "product.color": "Colour",
        "product.length": "Length",
        "product.option": "Option",
        "product.package-contents": "Delivery contents",
        "product.perMeter": "per meter",
        "product.priceUponRequest": "Price upon request",
        "product.share": "Share product",
        "product.system": "System",
        "product.configuration": "Configuration",
        "saved-to-clipboard": "Saved to clipboard",
        "share": "Share",
        "wishlist": "Wishlist",
        "wishlist.activation-status.synced.button": "Deactivate wishlist",
        "wishlist.activation-status.synced.text": "The wishlist can be opened by clicking the heart symbol in the navigation bar. New articles can be added directly from the respective system catalogue.",
        "wishlist.activation-status.synced.title": "Wishlist is active.",
        "wishlist.activation-status.unsynced.button": "Activate wishlist",
        "wishlist.activation-status.unsynced.text": "To add new articles, the saved wishlist must be activated.",
        "wishlist.activation-status.unsynced.title": "Wishlist is inactive.",
        "wishlist.add-to-cart": "Add to cart",
        "wishlist.add-all-to-cart": "Add to shopping cart",
        "wishlist.clear": "Clear list",
        "wishlist.delete": "Delete",
        "wishlist.download": "Download as CSV",
        "wishlist.edit": "Edit",
        "wishlist.empty.notice": "You can put together your own customised system using the wishlist. You can then share this wishlist with our experts.",
        "wishlist.excl-tax": "excl. VAT",
        "wishlist.headline": "Wishlist",
        "wishlist.price-from": "from",
        "wishlist.price-notice": "Prices are ex-works Weil am Rhein, Germany. Prices are subject to change without notice.",
        "wishlist.print": "Print/PDF",
        "wishlist.remove-product": "Remove article",
        "wishlist.request": "Request quote",
        "wishlist.request.headline": "Request for a quote",
        "wishlist.request.submit": "Non-binding request",
        "wishlist.save": "Save list",
        "wishlist.saved.headline": "Wishlist saved",
        "wishlist.saved.share-title": "My Visplay wishlist",
        "wishlist.saved.text": "The wishlist is now saved under the following link. This link can be shared. The wishlist can be edited by anyone with access to this link.",
        "wishlist.share": "Share list",
        "wishlist.synced.notice": "Synchronised with saved wishlist. Changes are automatically applied to the saved wishlist.",
        "wishlist.unlink": "Cancel synchronisation"
    },
    "es": {
        "remove": "eliminar",
        "size": "Variante",
        "cart.clear": "Vaciar el carrito de la compra",
        "cart.empty.notice": "Se pueden añadir artículos directamente desde el catálogo del sistema respectivo.",
        "cart.headline": "Carrito de la compra",
        "cart.to-checkout": "Pagar",
        "cart.sum": "Total",
        "cart.net": "Neto",
        "cart.subtotal": "Subtotal",
        "cart.tax": "Impuestos",
        "cart.tax.info": "Los impuestos se calculan según el país seleccionado.",
        "cart.shipping": "Gastos de envío",
        "cart.shipping.info": "Los gastos de envío se calculan según el país y el código postal.",
        "cart.discount": "Descuento",
        "field.discount_code": "Código de descuento",
        "configuration.share-title": "Configuración",
        "configuration.save-dialog.title": "Configuración guardada",
        "configuration.save-dialog.text": "La configuración se ha guardado. Puede acceder a ella con el enlace siguiente.",
        "configuration-request.configuration-data": "Datos de configuración",
        "configuration-request.partlist": "Lista de piezas",
        "continue": "Continuar",
        "copy-direct-link": "Copiar enlace directo",
        "copy-link": "Copiar enlace",
        "delivery-time.delivery_2w": "Plazo de entrega aprox. 2 semanas, ex fábrica Weil am Rhein, Alemania ",
        "delivery-time.delivery_6w": "Plazo de entrega aprox. 6 semanas, ex fábrica Weil am Rhein, Alemania ",
        "field.postal_code": "Código postal",
        "product-type.button.close": "cerrar",
        "product-type.view-image": "Ver imagen",
        "product.add-to-cart": "Añadir al carrito",
        "product.add-to-wishlist": "guardar",
        "product.amount": "Guardar en favoritos",
        "product.articleNumber": "Número de pieza",
        "product.color": "Color",
        "product.length": "Longitud",
        "product.option": "Opción",
        "product.package-contents": "Contenido del paquete",
        "product.perMeter": "Contenido de la entrega",
        "product.priceUponRequest": "Precio bajo consulta",
        "product.share": "Compartir producto",
        "product.system": "Sistema",
        "product.configuration": "Configuración",
        "saved-to-clipboard": "Guardado en el portapapeles",
        "share": "Compartir",
        "wishlist": "Lista de deseos",
        "wishlist.activation-status.synced.button": "Desactivar lista de deseos",
        "wishlist.activation-status.synced.text": "La lista de deseos puede abrirse haciendo clic en el símbolo del corazón de la barra de navegación. Los artículos nuevos pueden añadirse desde el catálogo del sistema correspondiente.",
        "wishlist.activation-status.synced.title": "La lista de deseos está activa.",
        "wishlist.activation-status.unsynced.button": "Activar lista de deseos",
        "wishlist.activation-status.unsynced.text": "Para añadir artículos nuevos a la lista de deseos guardada, ésta debe estar activa.",
        "wishlist.activation-status.unsynced.title": "La lista de deseos está inactiva.",
        "wishlist.add-to-cart": "Añadir al carrito",
        "wishlist.add-all-to-cart": "Añadir al carrito de la compra",
        "wishlist.clear": "Borrar lista",
        "wishlist.delete": "Eliminar",
        "wishlist.download": "Descargar (CSV)",
        "wishlist.edit": "Editar",
        "wishlist.empty.notice": "Con la lista de deseos puede crear su propio sistema y después compartir la lista de deseos con nuestros expertos.",
        "wishlist.excl-tax": "IVA no incluido",
        "wishlist.headline": "Lista de deseos",
        "wishlist.price-from": "a partir de",
        "wishlist.price-notice": "Los precios son desde la fábrica en Weil am Rhein, Alemania. Precios sujetos a cambios sin previo aviso.",
        "wishlist.print": "Imprimir/PDF",
        "wishlist.remove-product": "Eliminar artículo",
        "wishlist.request": "Solicitar presupuesto",
        "wishlist.request.headline": "Solicitar un presupuesto",
        "wishlist.request.submit": "Solicitud sin compromiso",
        "wishlist.save": "Guardar",
        "wishlist.saved.headline": "Lista de deseos guardada",
        "wishlist.saved.share-title": "Mi lista de deseos de Visplay",
        "wishlist.saved.text": "La lista de deseos se ha guardado en el siguiente enlace. Este enlace se puede compartir. La lista de deseos guardada puede ser editada por cualquiera con acceso a este enlace.",
        "wishlist.share": "Compartir lista",
        "wishlist.synced.notice": "Sincronizado con lista de deseos guardada. Los cambios se aplican automáticamente a la lista de deseos guardada.",
        "wishlist.unlink": "Cancelar sincronización"
    },
    "fr": {
        "remove": "supprimer",
        "size": "Variante",
        "cart.clear": "Vider le panier",
        "cart.empty.notice": "Ajouter de nouveaux articles à partir du catalogue du système concerné.",
        "cart.headline": "Panier",
        "cart.to-checkout": "Vers le paiement",
        "cart.sum": "Total",
        "cart.net": "Net",
        "cart.subtotal": "Sous-total",
        "cart.tax": "Taxes",
        "cart.tax.info": "Les taxes sont calculées après sélection du pays.",
        "cart.shipping": "Frais d’expédition",
        "cart.shipping.info": "Les frais de port sont calculés en fonction du pays et du code postal.",
        "cart.discount": "Code de réduction",
        "field.discount_code": "Code de réduction",
        "configuration.share-title": "Configuration",
        "configuration.save-dialog.title": "Configuration sauvegardée",
        "configuration.save-dialog.text": "La configuration a été sauvegardée. La configuration est désormais accessible en cliquant sur le lien suivant.",
        "configuration-request.configuration-data": "Données de configuration",
        "configuration-request.partlist": "Nomenclature",
        "continue": "Continuer",
        "copy-direct-link": "Copier le lien direct",
        "copy-link": "Copier le lien",
        "delivery-time.delivery_2w": "Délai de livraison env. 2 semaines, ex usine Weil am Rhein, Allemagne",
        "delivery-time.delivery_6w": "Délai de livraison env. 6 semaines, ex usine Weil am Rhein, Allemagne",
        "field.postal_code": "Code postal",
        "product-type.button.close": "fermer",
        "product-type.view-image": "Afficher l’image",
        "product.add-to-cart": "Ajouter au panier",
        "product.add-to-wishlist": "mémoriser",
        "product.amount": "Quantité",
        "product.articleNumber": "Référence de l’article ",
        "product.color": "Couleur",
        "product.length": "Longueur",
        "product.option": "Option",
        "product.package-contents": "Contenu du colis",
        "product.perMeter": "par mètre",
        "product.priceUponRequest": "Prix sur demande",
        "product.share": "Partager le produit",
        "product.system": "Système",
        "product.configuration": "Configuration",
        "saved-to-clipboard": "Sauvegardé dans le presse-papiers",
        "share": "Partager",
        "wishlist": "Liste aide-mémoire",
        "wishlist.activation-status.synced.button": "Désactiver la liste aide-mémoire",
        "wishlist.activation-status.synced.text": "Vous pouvez accéder à la liste aide-mémoire en cliquant sur l’icône en forme de cœur dans la barre de navigation. Il est possible d’ajouter de nouveaux articles à partir du catalogue du système concerné.",
        "wishlist.activation-status.synced.title": "La liste aide-mémoire est active.",
        "wishlist.activation-status.unsynced.button": "Activer la liste aide-mémoire",
        "wishlist.activation-status.unsynced.text": "Pour ajouter de nouveaux articles à cette liste aide-mémoire mémorisée, il faut activer la liste.",
        "wishlist.activation-status.unsynced.title": "La liste aide-mémoire est désactivée.",
        "wishlist.add-to-cart": "Ajouter au panier",
        "wishlist.add-all-to-cart": "Ajouter au panier",
        "wishlist.clear": "Vider la liste",
        "wishlist.delete": "Supprimer",
        "wishlist.download": "Télécharger au format CSV",
        "wishlist.edit": "Modifier",
        "wishlist.empty.notice": "Cette liste aide-mémoire vous permet de configurer votre propre système. Vous avez ensuite la possibilité de partager cette liste avec notre équipe de spécialistes.",
        "wishlist.excl-tax": "hors taxes",
        "wishlist.headline": "Liste aide-mémoire",
        "wishlist.price-from": "à partir de",
        "wishlist.price-notice": "Les prix s’entendent départ usine Weil am Rhein, Allemagne. Sous réserve de modifications.",
        "wishlist.print": "Imprimer/PDF",
        "wishlist.remove-product": "Supprimer des articles",
        "wishlist.request": "Demander un devis",
        "wishlist.request.headline": "Demande de devis",
        "wishlist.request.submit": "Faire une demande sans engagement",
        "wishlist.save": "Sauvegarder la liste",
        "wishlist.saved.headline": "Liste aide-mémoire enregistrée",
        "wishlist.saved.share-title": "Ma liste aide-mémoire Visplay",
        "wishlist.saved.text": "Votre liste aide-mémoire est désormais sauvegardée sous le lien suivant. Vous pouvez partager ce lien. La liste aide-mémoire peut être modifiée par quiconque connaît ce lien.",
        "wishlist.share": "Partager la liste",
        "wishlist.synced.notice": "Synchronisé avec une liste aide-mémoire enregistrée. Les modifications sont automatiquement reprises dans la liste aide-mémoire enregistrée.",
        "wishlist.unlink": "Supprimer le lien"
    }
}

export default translations;