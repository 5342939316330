class MImageNav {
	/** @param {HTMLElement} element */
	constructor(element) {
		this.element = element;
		this.linkElements = element.querySelectorAll('.m-image-nav__link');
		this.imageElements = element.querySelectorAll('.m-image-nav-list__images > div');

		this.linkElements.forEach((linkElement) => {
			linkElement.addEventListener('mouseenter', () => this.showImage(linkElement.dataset.target));
			if (linkElement.tagName === 'BUTTON') {
				linkElement.addEventListener('click', () => this.showImage(linkElement.dataset.target));
			}
		});
	}

	showImage(id) {
		this.imageElements.forEach((imageElement) => {
			imageElement.toggleAttribute('data-selected', imageElement.id === id);
		});

		this.linkElements.forEach((linkElement) => {
			if (linkElement.tagName === 'BUTTON') {
				linkElement.setAttribute('aria-pressed', linkElement.dataset.target === id ? 'true' : 'false');
			}
		});
	}
}

document.querySelectorAll('.m-image-nav-list').forEach((_) => new MImageNav(_));
