import {
	LitElement,
	html,
	css,
	nothing,
} from 'lit';

class ViInput extends LitElement {
	static properties = {
		value: { type: String },
	};

	static styles = css`
		:host {
			--_enhancer-padding: var(--gap-small);
			display: block;
		}
		:host([variant="inline"]) {
			display: grid;
			column-gap: var(--gap-x-small);
			grid-template-columns: max-content max-content;
		}
		:host([size="small"]) {
			--_enhancer-padding: var(--gap-x-small);
		}

		.container {
			display: grid;
			grid-template-columns: max-content 1fr max-content;
		}
		.container > * {
			grid-row-start: 1;
		}

		slot[name="start"],
		slot[name="end"] {
			z-index: 1;
			display: flex;
			padding-inline: var(--_enhancer-padding);
			align-items: center;
			pointer-events: none;
		}
		:where(slot[name="start"], slot[name="end"])::slotted(button) {
			pointer-events: auto;
		}

		slot[name="start"] {
			grid-column: 1 / 2;
		}

		slot[name="end"] {
			grid-column: -2 / -1;
		}

		slot[name="input"] {
			display: block;
			grid-column: 1 / -1;
			box-sizing: border-box;
			inline-size: 100%;
		}

		slot[name="label"] {
			display: flex;
			align-items: center;
			font: var(--font-label-medium);
		}
	`;

	#onChange(event) {
		const { target } = event;
		this.value = target.value;
		this.dispatchEvent(new CustomEvent('change'));

		if (target.checkValidity() === false) {
			console.log(target.validationMessage);
			// TODO show validation error
		}
	}

	async #onSlotInputChange(event) {
		this.inputElement = event.target.assignedNodes()[0] ?? null;
		this.inputElement?.addEventListener('input', (_) => {
			this.#onChange(_);
		});
	}

	#onFormSubmit(event) {
		const { inputElement } = this;
		if (inputElement.validity.valid === false) {
			this.shadowRoot.querySelector('input').reportValidity();
			event.preventDefault();
		}
	}

	hasEndSlot() {
		return this.shadowRoot.querySelector('slot[name="end"]')?.assignedElements({ flatten: true }).length;
	}

	render() {
		const style = {};
		if (this.shadowRoot.querySelector('slot[name="start"]')?.assignedNodes().length > 0) {
			const startSlot = this.shadowRoot.querySelector('slot[name="start"]');
			style['padding-inline-start'] = `${startSlot.offsetWidth}px`;
		}
		if (this.shadowRoot.querySelector('slot[name="end"]')?.assignedNodes().length > 0) {
			const endSlot = this.shadowRoot.querySelector('slot[name="end"]');
			style['padding-inline-end'] = `${endSlot.offsetWidth}px`;
		}

		if (this.inputElement) {
			Object.assign(this.inputElement.style, style);
		}

		return html`
			<slot name="label"><label for="${this.name ?? nothing}"></label></slot>
			<div class="container">
				<slot
					name="start"
					@slotchange="${this.onSlotStartChange}"
				></slot>
				<slot name="input" @slotchange="${this.#onSlotInputChange}"></slot>
				<slot
					name="end"
					@slotchange="${this.onSlotEndChange}"
				></slot>
			</div>
		`;
	}
}

if (!customElements.get('vi-input')) {
	customElements.define('vi-input', ViInput);
}
